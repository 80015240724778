<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("bio_information") }}
                    </div>
                    <Divider />
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                        <li>
                          {{
                            $t(
                              "names_provided_should_be_as_seen_on_your_national_id"
                            )
                          }}
                        </li>
                        <li>
                          {{
                            $t(
                              "all_telephone_numbers_must_start_with_the_country_code"
                            )
                          }}
                        </li>
                        <li>
                          Eg. <span class="text-green-500">+233 209013836</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div class="">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("proof_of_nationality") }}
                          </div>
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("national_id_type") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('national_id_type')" optionLabel="label"
                                  optionValue="value" ref="ctrlnational_id_type" v-model="formData.national_id_type"
                                  :options="$menus.national_id_typeItems" :label="$t('national_id_type')"
                                  :placeholder="$t('select')" :disabled="formData.national_id_type_disbale">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("enter_id_number") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputMask v-if="formData.national_id_type == ghanacard" ref="ctrlcard_number"
                                  :label="$t('enter_id_number')" type="text" inputClass="w-full"
                                  placeholder="GHA-000000000-0" v-model.trim="formData.national_id_number" class="w-full"
                                  mask="aaa-999999999-9" style="text-transform: uppercase"
                                  :class="getErrorClass('national_id_number')">
                                </InputMask>

                                <InputText v-if="formData.national_id_type != ghanacard" ref="ctrlnational_id_number"
                                  v-model.trim="formData.national_id_number" :label="$t('enter_id_number')" type="text"
                                  inputClass="w-full" :placeholder="$t('enter_id_number')" pattern="^[A-Za-z0-9-]+$"
                                  class="w-full" :class="getErrorClass('national_id_number')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="mt-3 mb-2 font-bold text-sm">
                                {{
                                  $t(
                                    "upload_a_copy_of_the_provided_national_id"
                                  )
                                }}
                                *
                              </div>
                              <small class="text-xs text-primary">{{
                                $t(
                                  "the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB"
                                )
                              }}
                                .pdf .jpg .png .jpeg</small>
                              <div class="mt-2">
                                <!-- <div class="field mb-0"> -->
                                <Uploader :class="getErrorClass('scanned_national_id_card')
                                    " :auto="true" :fileLimit="1" :maxFileSize="10000000" accept=".jpg,.png,.pdf,.jpeg"
                                  :multiple="false" style="width: 100%" :label="$t('choose_files_or_drop_files_here')"
                                  upload-path="s3uploader/upload/scanned_national_id_card"
                                  v-model="formData.scanned_national_id_card"></Uploader>
                                <!-- </div> -->
                              </div>
                              <div v-if="formData.scanned_national_id_card" class="mx-2 mb-2 mt-2 text-sm">
                                <p>
                                  <a style="text-decoration: none" class="btn font-bold animate__shakeX text-green-500"
                                    target="_blank" :href="$utils.setFileUrl(
                                      formData.scanned_national_id_card
                                    )
                                      ">{{
    $t("click_here_to_view_uploaded_document")
  }}</a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div v-if="formData.national_id_type == ghanacard" class="">
                            <div v-if="formData.national_id_verify_count > 2" class="mb-3 text-blue-400 text-sm">
                              {{
                                $t(
                                  "you_have_2_attempts_to_validate_ghana_card_number"
                                )
                              }}
                              <span class="font-bold">{{ formData.national_id_verify_count }}/2
                                attempts</span>
                            </div>
                            <Button v-if="formData.national_id_verify_count < 2" :label="'Click to Validate Ghana Card'"
                              class="p-button-info p-button-sm w-full mt-2" :loading="verifying"
                              @click="verifyNationalID" />
                          </div>
                        </div>
                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("personal_information") }}
                          </div>

                          <Message class="mb-3" icon="pi pi-sen" severity="info" :closable="false">HINT: Please make sure
                            your Full Name is the same as what appears on your previous academic documents, such as WASSCE
                            Results, Degree Certificate, HND, NABPTEX, SSSCE results, etc. If your name has been changed,
                            please attach supporting documents below this form.
                          </Message>
                          <!--[form-content-start]-->
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("title") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('title')" optionLabel="label"
                                  optionValue="value" ref="ctrltitle" v-model="formData.title" :options="applicantTitles"
                                  :label="$t('title')" :placeholder="$t('select')" @change="validateGenderAndTitle">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.title == other" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_other") }} {{ $t("title") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrltitle_other" v-model.trim="formData.title_other" :label="$t('title')"
                                  type="text" :placeholder="$t('title')" class="w-full"
                                  :class="getErrorClass('title_other')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("first_name") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlfirstname" v-model.trim="formData.firstname" :label="$t('first_name')"
                                  type="text" :placeholder="$t('first_name')" class="w-full"
                                  :class="getErrorClass('firstname')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("last_name") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrllastname" v-model.trim="formData.lastname" :label="$t('last_name')"
                                  type="text" :placeholder="$t('last_name')" class="w-full"
                                  :class="getErrorClass('lastname')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("other_names") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlothernames" v-model.trim="formData.othernames"
                                  :label="$t('other_names')" type="text" :placeholder="$t('other_names')" class="w-full"
                                  :class="getErrorClass('othernames')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>

                          <div class="grid mt-2">
                            <div class="col-12 md:col-12 xs:col-12">
                              <div class="font-bold text-sm">
                                {{ $t("day_of_birth") }} *
                              </div>
                              <div class="text-sm text-green-500">
                                {{ applicantAge }}
                              </div>
                            </div>
                            <div class="col-12 md:col-4 sm:col-4 xs:col-4">
                              <div class="mb-2 text-sm text-blue-400">
                                {{ $t("day") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('dob_day')" optionLabel="label"
                                  optionValue="value" ref="ctrldob_day" v-model="formData.dob_day"
                                  :options="$menus.days_list" :label="$t('dob_day')" :placeholder="$t('select')"
                                  @change="validateAge">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-4 sm:col-4 xs:col-4">
                              <div class="mb-2 text-sm text-blue-400">
                                {{ $t("month") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('dob_month')" optionLabel="label"
                                  optionValue="value" ref="ctrldob_month" v-model="formData.dob_month"
                                  :options="$menus.dob_monthItems" :label="$t('month_of_birth')"
                                  :placeholder="$t('select')" @change="validateAge">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-4 sm:col-4 xs:col-4">
                              <div class="mb-2 text-sm text-blue-400">
                                {{ $t("year") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('dob_year')" ref="ctrldob_year"
                                  v-model="formData.dob_year" :options="$utils.years(formData.dob_year, 'dob')
                                    " :label="$t('dob_year')" :placeholder="$t('select')" @change="validateAge">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>

                          <div class="grid mt-3">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("home_town_region") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('hometown_region')" optionLabel="label"
                                  optionValue="value" ref="ctrlhometown_region" :filter="true"
                                  v-model="formData.hometown_region" :options="$menus.regions_list"
                                  :label="$t('hometown_region')" :placeholder="$t('select')">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("home_town") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlhometown" v-model.trim="formData.hometown" :label="$t('home_town')"
                                  type="text" :placeholder="$t('home_town')" class="w-full"
                                  :class="getErrorClass('hometown')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("religious_denomination") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('religious_denomination')
                                  " optionLabel="label" optionValue="value" ref="ctrlreligious_denomination"
                                  v-model="formData.religious_denomination" :options="$menus.religions_list"
                                  :filter="true" :label="$t('religious_denomination')" :placeholder="$t('select')">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.religious_denomination == other" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_other") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlreligious_denomination_other" v-model.trim="formData.religious_denomination_other
                                  " :label="$t('specify_other')" type="text" :placeholder="$t('specify_other')"
                                  class="w-full" :class="getErrorClass(
                                    'religious_denomination_other'
                                  )
                                    ">
                                </InputText>
                                <small class="text-xs text-primary mx-1">{{ $t("specify_other") }}
                                  {{ $t("religious_denomination") }}</small>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("marital_status") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('marital_status')" optionLabel="label"
                                  optionValue="value" ref="ctrlmarital_status" v-model="formData.marital_status"
                                  :options="$menus.marital_statusItems" :label="$t('marital_status')"
                                  :placeholder="$t('select')">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("gender") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('gender')" optionLabel="label"
                                  optionValue="value" ref="ctrlgender" v-model="formData.gender"
                                  :options="$menus.genderItems" :label="$t('gender')" :placeholder="$t('select')"
                                  @change="validateGenderAndTitle">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-1 text-blue-500 font-bold text-md">
                            {{ $t("primary_contact") }}
                          </div>
                          <div class="mb-3 text-blue-600 text-sm">
                            {{ $t("please_provide_a_valid_phone_number") }}
                          </div>

                          <div class="grid">
                            <div class="col-12 md:col-4">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("dial_code") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('dial_code')" optionLabel="dial_code"
                                  optionValue="value" ref="ctrldial_code" :filterFields="['name', 'dial_code']"
                                  :filter="true" v-model="formData.dial_code" :options="$menus.dial_codes_list"
                                  :label="$t('dial_code')" :placeholder="$t('select')">
                                  <template #option="slotProps">
                                    <div class="flex align-items-center">
                                      <div>
                                        <div>
                                          {{ slotProps.option.name }}
                                        </div>
                                        <div v-if="slotProps.option.name" class="text-sm text-500">
                                          {{ slotProps.option.code }}
                                          {{ slotProps.option.dial_code }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-8">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("primary_telephone") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlprimary_telephone" v-model.trim="formData.primary_telephone"
                                  :label="$t('primary_telephone')" type="text" :placeholder="$t('primary_telephone')"
                                  pattern="^[0-9]+$" class="w-full" maxlength="10" minlength="8"
                                  :class="getErrorClass('primary_telephone')" v-tooltip.top.focus="'Eg: 0209013836'">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("email_address") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlemail_address" v-model.trim="formData.email_address"
                                  :label="$t('email_address')" type="email" :placeholder="$t('email_address')"
                                  class="w-full" maxlength="40" :class="getErrorClass('email_address')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("address_of_applicant") }}
                          </div>

                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_1") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrladdress_line1" v-model.trim="formData.address_line1"
                                  :label="$t('address_line_1')" type="text" :placeholder="$t('address_line_1')"
                                  class="w-full" :class="getErrorClass('address_line1')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_2") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrladdress_line2" v-model.trim="formData.address_line2"
                                  :label="$t('address_line_2')" type="text" :placeholder="$t('address_line_2')"
                                  class="w-full" :class="getErrorClass('address_line2')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_3") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrladdress_line3" v-model.trim="formData.address_line3"
                                  :label="$t('address_line_3')" type="text" :placeholder="$t('address_line_3')"
                                  class="w-full" :class="getErrorClass('address_line3')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_4") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrladdress_line4" v-model.trim="formData.address_line4"
                                  :label="$t('address_line_4')" type="text" :placeholder="$t('address_line_4')"
                                  class="w-full" :class="getErrorClass('address_line4')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("digital_address") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrladdress_line4" v-model.trim="formData.digital_address"
                                  :label="$t('digital_address')" type="text" :placeholder="$t('digital_address')"
                                  pattern="^[a-zA-Z]{2}[0-9-]+$" class="w-full" maxlength="14"
                                  :class="getErrorClass('digital_address')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("street_address") }} ({{
                                  $t("for_courier_services")
                                }})
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlstreet_address" v-model.trim="formData.street_address"
                                  :label="$t('street_address')" type="text" :placeholder="$t('street_address')"
                                  class="w-full" :class="getErrorClass('street_address')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("permanent_home_address") }}
                          </div>
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="field-checkbox" v-for="option of $menus.same_as_address_aboveItems"
                                :key="option.value">
                                <Checkbox :class="getErrorClass('same_address_as_ph_address')
                                  " :id="option.value" name="ctrlsame_address_as_ph_address" :value="option.value"
                                  v-model="formData.same_address_as_ph_address" @change="populatePermanentHomeAddress" />
                                <label> {{ option.label }}</label>
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_1") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlph_address_line1" v-model.trim="formData.ph_address_line1"
                                  :label="$t('address_line_1')" type="text" :placeholder="$t('address_line_1')"
                                  class="w-full" :class="getErrorClass('ph_address_line1')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_2") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlph_address_line2" v-model.trim="formData.ph_address_line2"
                                  :label="$t('address_line_2')" type="text" :placeholder="$t('address_line_2')"
                                  class="w-full" :class="getErrorClass('ph_address_line2')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_3") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlph_address_line3" v-model.trim="formData.ph_address_line3"
                                  :label="$t('address_line_3')" type="text" :placeholder="$t('enter_address_line_3')"
                                  class="w-full" :class="getErrorClass('ph_address_line3')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("address_line_4") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlph_address_line4" v-model.trim="formData.ph_address_line4"
                                  :label="$t('address_line_4')" type="text" :placeholder="$t('address_line_4')"
                                  class="w-full" :class="getErrorClass('ph_address_line4')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("guardian_parent_next_of_kin_information") }}
                          </div>
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_firstname") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlguardian_firstname" v-model.trim="formData.guardian_firstname"
                                  :label="$t('guardians_firstname')" type="text" :placeholder="$t('guardians_firstname')"
                                  class="w-full" :class="getErrorClass('guardian_firstname')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_lastname") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlguardian_lastname" v-model.trim="formData.guardian_lastname"
                                  :label="$t('guardians_lastname')" type="text" :placeholder="$t('guardians_lastname')"
                                  class="w-full" :class="getErrorClass('guardian_lastname')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_telephone") }} *
                              </div>
                              <div class="grid">
                                <div class="col-12 md:col-3">
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                    <Dropdown class="w-full" :class="getErrorClass(
                                      'guardian_telephone_dial_code'
                                    )
                                      " optionLabel="dial_code" optionValue="value"
                                      ref="ctrlguardian_telephone_dial_code" :filter="true"
                                      :filterFields="['name', 'dial_code']" v-model="formData.guardian_telephone_dial_code
                                        " :options="$menus.dial_codes_list" :label="$t('dial_code')"
                                      :placeholder="$t('dial_code')">
                                      <template #option="slotProps">
                                        <div class="flex align-items-center">
                                          <div>
                                            <div>
                                              {{ slotProps.option.name }}
                                            </div>
                                            <div v-if="slotProps.option.name" class="text-sm text-500">
                                              {{ slotProps.option.code }}
                                              {{ slotProps.option.dial_code }}
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                    </Dropdown>
                                    <!-- </div> -->
                                  </div>
                                </div>
                                <div class="col-12 md:col-9">
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                    <InputText ref="ctrlguardian_telephone" v-model.trim="formData.guardian_telephone"
                                      :label="$t('guardians_telephone')" type="text"
                                      :placeholder="$t('guardians_telephone')" pattern="^[0-9]+$" class="w-full"
                                      maxlength="10" minlength="8" :class="getErrorClass('guardian_telephone')
                                        " v-tooltip.top.focus="'Eg: 0209013836'">
                                    </InputText>
                                    <!-- </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_occupation") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('guardian_occupation')" optionLabel="label"
                                  optionValue="value" :filter="true" ref="ctrlguardian_occupation"
                                  v-model="formData.guardian_occupation" :options="$menus.occupations_list"
                                  :label="$t('guardians_occupation')" :placeholder="$t('select')">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.guardian_occupation == other" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_other") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlguardian_occupation_other" v-model.trim="formData.guardian_occupation_other
                                  " :label="$t('specify_other')" type="text" :placeholder="$t('specify_other')"
                                  class="w-full" :class="getErrorClass('guardian_occupation_other')
                                    ">
                                </InputText>
                                <small class="text-xs text-primary mx-1">{{ $t("specify_other") }}
                                  {{ $t("guardians_occupation") }}</small>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_email") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlguardian_email" v-model.trim="formData.guardian_email"
                                  :label="$t('guardians_email')" type="email" :placeholder="$t('guardians_email')"
                                  class="w-full" maxlength="40" :class="getErrorClass('guardian_email')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_address_line_1") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlguardian_address1" v-model.trim="formData.guardian_address1"
                                  :label="$t('guardians_address_line_1')" type="text" :placeholder="$t('address_line_1')"
                                  class="w-full" :class="getErrorClass('guardian_address1')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_address_line_2") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlguardian_address2" v-model.trim="formData.guardian_address2"
                                  :label="$t('guardians_address_line_2')" type="text" :placeholder="$t('address_line_2')"
                                  class="w-full" :class="getErrorClass('guardian_address2')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("guardians_address_line_3") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlguardian_address3" v-model.trim="formData.guardian_address3"
                                  :label="$t('guardians_address_line_3')" type="text" :placeholder="$t('address_line_3')"
                                  class="w-full" :class="getErrorClass('guardian_address3')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("sports_information") }}
                          </div>
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("do_you_do_any_sports") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('is_athlete')" optionLabel="label"
                                  optionValue="value" ref="ctrlis_athlete" v-model="formData.is_athlete"
                                  :options="$menus.yes_or_noItems" :label="$t('do_you_do_any_sports')"
                                  :placeholder="$t('select')" @change="validateIsAthlete">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.is_athlete == yes" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_discipline") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <MultiSelect class="w-full" :class="getErrorClass('last_competition')" optionLabel="label"
                                  optionValue="value" ref="ctrllast_competition" v-model="formData.last_competition"
                                  :options="$menus.last_competitionItems" :label="$t('specify_discipline')"
                                  :placeholder="$t('select')">
                                </MultiSelect>
                                <small class="text-xs text-primary mx-1">{{
                                  $t("you_can_select_multiple_disciplines")
                                }}</small>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.is_athlete == yes" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{
                                  $t("would_you_like_to_represent_institution")
                                }}
                                *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('sports_rep_institution')
                                  " optionLabel="label" optionValue="value" ref="ctrlsports_rep_institution"
                                  v-model="formData.sports_rep_institution" :options="$menus.yes_or_noItems" :label="$t(
                                    'would_you_like_to_represent_institution'
                                  )
                                    " :placeholder="$t('select')">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("disability_special_needs_information") }}
                          </div>
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("do_you_have_any_disability") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('is_disable')" optionLabel="label"
                                  optionValue="value" ref="ctrlis_disable" v-model="formData.is_disable"
                                  :options="$menus.yes_or_noItems" :label="$t('do_you_have_any_disability')"
                                  :placeholder="$t('select')" @change="validateIsDisable">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.is_disable == yes" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_disability") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <MultiSelect class="w-full" :class="getErrorClass('disability')" optionLabel="label"
                                  optionValue="value" optionGroupLabel="label" optionGroupChildren="items" :filter="true"
                                  ref="ctrldisability" v-model="formData.disability" :options="$menus.disabilityItems"
                                  :label="$t('specify_disability')" :placeholder="$t('select_a_value')"
                                  @change="validateSelectedDisability">
                                  <template #optiongroup="slotProps">
                                    <hr class="mt-0" />
                                    <div class="flex align-items-center text-primary font-bold">
                                      <div>{{ slotProps.option.label }}</div>
                                    </div>
                                    <hr class="mb-0" />
                                  </template>
                                </MultiSelect>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="showOtherDisabilityField == true" class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_all_other_disability") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrldisability_other" v-model.trim="formData.disability_other"
                                  :label="$t('specify_other')" type="text" :placeholder="$t('specify_other')"
                                  class="w-full" :class="getErrorClass('disability_other')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("employment_information") }}
                          </div>
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("are_you_employed") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('is_employed')" optionLabel="label"
                                  optionValue="value" ref="ctrlis_employed" v-model="formData.is_employed"
                                  :options="$menus.yes_or_noItems" :label="$t('are_you_employed')"
                                  :placeholder="$t('select')" @change="validateIsEmployed">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.is_employed == yes" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("nature_of_employment") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('employment_nature')" optionLabel="label"
                                  optionValue="value" :filter="true" ref="ctrlemployment_nature"
                                  v-model="formData.employment_nature" :options="$menus.occupations_list"
                                  :label="$t('nature_of_employment')" :placeholder="$t('select')">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.employment_nature == other" class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_other") }}
                                *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlemployment_nature_other" v-model.trim="formData.employment_nature_other
                                  " :label="$t('specify_other')" type="text" :placeholder="$t('specify_other')"
                                  class="w-full" :class="getErrorClass('employment_nature_other')
                                    ">
                                </InputText>
                                <small class="text-xs text-primary mx-1">{{ $t("specify_other") }}
                                  {{ $t("nature_of_employment") }}</small>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.is_employed == yes &&
                              formData.employment_nature != unemployed
                              " class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("name_and_address_of_employer") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Textarea :class="getErrorClass('employment_address')" class="w-full"
                                  ref="ctrlemployment_address" rows="2" v-model="formData.employment_address"
                                  :placeholder="$t('name_and_address_of_employer')
                                    " type="textarea">
                                    </Textarea>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("upload_passport_size_photograph") }}
                          </div>
                          <div class="grid">
                            <div class="col-12 md:col-7">
                              <div class="mt-3 mb-2 font-bold text-sm">
                                {{ $t("upload_passport_size_photograph") }}
                                *
                              </div>
                              <small class="text-xs text-primary">{{
                                $t(
                                  "the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB"
                                )
                              }}
                                .jpg .png .jpeg</small>
                              <br>
                              <small class="text-purple-500">Picture MUST have white background and passport size photo
                                dimension (35x45 mm).</small>
                              <br>
                              <small>You can use this <a target="_blank" :href="$utils.getFileFullPath('photo-tool')"
                                  class="text-blue-400">Photo Checker</a> to crop your passport photo before
                                uploading</small>
                              <div class="mt-2">
                                <!-- <div class="field mb-0"> -->
                                <Uploader :class="getErrorClass('passport_picture')" :auto="true" :fileLimit="1"
                                  :maxFileSize="10000000" accept=".jpg,.png,.jpeg" :multiple="false" style="width: 100%"
                                  :label="$t('choose_files_or_drop_files_here')"
                                  upload-path="s3uploader/upload/passport_picture" v-model="formData.passport_picture">
                                </Uploader>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-5">
                              <div class="text-center mt-3 mb-2 font-bold text-sm">
                                {{ $t("image_preview") }}
                              </div>
                              <div class="text-center">
                                <img style="width: 60%" :src="$utils.setFileUrl(formData.passport_picture)
                                  " />
                              </div>
                            </div>
                          </div>
                        </div>


                        <!-- Revelant Documents -->
                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-pink-500 font-bold text-md">
                            {{ $t("Revelant Documents (If Any)") }}
                          </div>

                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="mt-3 mb-2 font-bold text-sm">
                                {{ $t("Please upload any other documents that will be relevant to your Application") }}
                              </div>
                              <div class="text-xs">
                                Document Examples: <b> Affidavit, Birth Certicate, Proof of Residence, etc. </b>
                              </div>
                              <small class="text-xs text-primary">{{
                                $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB") }}
                                .jpg,.png,.pdf,.jpeg,.doc,.docx
                              </small>
                              <br>
                              <small class="text-xs text-primary">Max files limit is 10. </small>
                              <div class="mt-2">
                                <Uploader :auto="true" :fileLimit="10" :class="getErrorClass('relevant_documents')"
                                  :maxFileSize="10000000" accept=".jpg,.png,.pdf,.jpeg,.doc,.docx" :multiple="true"
                                  style="width: 100%" :label="$t('choose_files_or_drop_files_here')"
                                  upload-path="s3uploader/upload/relevant_documents"
                                  v-model="formData.relevant_documents">
                                </Uploader>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button type="submit" :label="$t('save_and_continue')" icon="pi pi-send" :loading="saving" />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { ApiService } from "../../services/api";
import {
  required,
  requiredIf,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "biodataApplicantsPage",
  components: {},
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsbiodata",
    },
    pagePath: {
      type: String,
      default: "applicants/biodata",
    },
    apiPath: {
      type: String,
      default: "applicants/biodata",
    },
    pageIndex: { type: Number, default: 1 },
  },
  data() {
    return {
      formData: {
        is_athlete: this.na,
        sports_rep_institution: this.na,
        is_disable: this.na,
        is_employed: this.na,
        same_address_as_ph_address: this.na,
        guardian_occupation: "",
        guardian_telephone: "",
        passport_picture: "",
        last_competition: "",
        disability: "",
        employment_nature: "",
        employment_address: "",
        employment_nature_other: "",
        guardian_occupation_other: "",
        disability_other: "",
        religious_denomination_other: "",
        scanned_national_id_card: "",
        national_id_type: "",
        national_id_number: "",
        title: "",
        title_other: "",
        fullname: "",
        firstname: "",
        lastname: "",
        othernames: "",
        hometown: "",
        hometown_region: "",
        dial_code: "",
        primary_telephone: "",
        gender: "",
        religious_denomination: "",
        marital_status: "",
        dob_day: "",
        dob_month: "",
        dob_year: "",
        email_address: "",
        address_line1: "",
        address_line2: "",
        address_line3: "",
        address_line4: "",
        street_address: "",
        digital_address: "",
        ph_address_line1: "",
        ph_address_line2: "",
        ph_address_line3: "",
        ph_address_line4: "",
        guardian_firstname: "",
        guardian_lastname: "",
        guardian_email: "",
        guardian_address1: "",
        guardian_address2: "",
        guardian_address2: "",
        guardian_telephone_dial_code: "",
        id_card_verified_name: "",
        id_card_verified_dob: "",
        id_card_name_different: this.na,
        national_id_verify_count: 0,
        relevant_documents: "",
      },
      no: "No",
      yes: "Yes",
      na: "N/A",
      other: "Other",
      unemployed: "Unemployed",
      ghanacard: "Ghana Card",
      passport: "Passport",
      applicantAge: "",
      submitted: false,
      verifying: false,
      leapYears: [
        1904, 1908, 1912, 1916, 1920, 1924, 1928, 1932, 1936, 1940, 1944, 1948,
        1952, 1956, 1960, 1964, 1968, 1972, 1976, 1980, 1984, 1988, 1992, 1996,
        2000, 2004, 2008, 2012, 2016, 2020, 2024,
      ],
      monthsWith30days: ["September", "April", "June", "November"],
      applicantTitles: [],
      maleTitles: ["Mr", "Master"],
      femaleTitles: ["Miss", "Mrs", "Reverend Sister"],
      nationalIdTypes: [
        { value: "Ghana Card", label: "Ghana Card" },
        { value: "Passport", label: "Passport" },
        { value: "Voters ID", label: "Voters ID" },
      ],
      showOtherDisabilityField: false,
    };
  },
  computed: {
    ...mapGetters("applicants", ["bioDataRecord"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      is_athlete: { required },
      sports_rep_institution: {},
      is_disable: { required },
      is_employed: { required },
      same_address_as_ph_address: {},
      passport_picture: { required },
      last_competition: {
        required: requiredIf(() => {
          const esit = this.formData.is_athlete == this.yes;
          return esit;
        }),
      },
      disability: {
        required: requiredIf(() => {
          const esit = this.formData.is_disable == this.yes;
          return esit;
        }),
      },
      employment_nature: {
        required: requiredIf(() => {
          const esit = this.formData.is_employed == this.yes;
          return esit;
        }),
      },
      employment_address: {},
      employment_nature_other: {
        required: requiredIf(() => {
          const esit = this.formData.employment_nature == this.other;
          return esit;
        }),
      },
      disability_other: {
        required: requiredIf(() => {
          const esit = this.showOtherDisabilityField == true;
          return esit;
        }),
      },
      religious_denomination_other: {
        required: requiredIf(() => {
          const esit = this.formData.religious_denomination == this.other;
          return esit;
        }),
      },
      scanned_national_id_card: {
        required: requiredIf(() => {
          const esit = this.formData.national_id_type != this.ghanacard;
          return esit;
        }),
      },
      national_id_type: { required },
      national_id_number: { required },
      title: { required },
      title_other: {
        required: requiredIf(() => {
          const esit = this.formData.title == this.other;
          return esit;
        }),
      },
      fullname: {},
      firstname: { required },
      lastname: { required },
      othernames: {},
      hometown: {},
      hometown_region: {},
      dial_code: { required },
      primary_telephone: { required },
      gender: { required },
      religious_denomination: { required },
      marital_status: { required },
      dob_day: { required },
      dob_month: { required },
      dob_year: { required },
      email_address: { required, email },
      address_line1: { required },
      address_line2: {},
      address_line3: {},
      address_line4: {},
      street_address: {},
      digital_address: {},
      ph_address_line1: { required },
      ph_address_line2: {},
      ph_address_line3: {},
      ph_address_line4: {},
      guardian_telephone_dial_code: { required },
      guardian_firstname: { required },
      guardian_lastname: { required },
      guardian_email: { email },
      guardian_address1: { required },
      guardian_address2: {},
      guardian_address3: {},
      guardian_occupation_other: {
        required: requiredIf(() => {
          const esit = this.formData.guardian_occupation == this.other;
          return esit;
        }),
      },
      guardian_occupation: { required },
      guardian_telephone: { required },
      relevant_documents: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", ["updateBioData", "fetchBioData"]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchBioData(url).then(
        (response) => {
          this.loading = false;
          this.ready = true;
          // this.bioDataRecord = response.data;
          this.formData = response.data;
          // Add other specified to Titles Dropdown
          let find_title = this.applicantTitles.find(
            (el) => el.value === this.formData.title
          );
          if (!find_title) {
            this.applicantTitles.push({
              value: this.formData.title,
              label: this.formData.title,
            });
          }
          this.updateFormFields();
          this.checkStudentNationality();
          this.validateSelectedDisability();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      if (this.formData.title_other) {
        payload.title = this.formData.title_other;
      }
      let data = { id, url, payload };
      this.updateBioData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          this.$emit("next", this.pageIndex);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
      this.formData.last_competition = this.$utils.toArray(
        this.formData.last_competition
      );
      this.formData.same_address_as_ph_address = this.$utils.toArray(
        this.formData.same_address_as_ph_address
      );
      this.formData.disability = this.$utils.toArray(this.formData.disability);
    },
    resetForm() {
      //reset form fields value
      this.formData = {};
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    validateGenderAndTitle() {
      var msg =
        "Invalid selections. '" +
        this.formData.title +
        "' was chosen as title and '" +
        this.formData.gender +
        "' as gender.";
      if (
        this.femaleTitles.includes(this.formData.title) &&
        this.formData.gender == "Male"
      ) {
        this.showPageRequestError(msg);
        this.formData.gender = "";
      } else if (
        this.maleTitles.includes(this.formData.title) &&
        this.formData.gender == "Female"
      ) {
        this.showPageRequestError(msg);
        this.formData.gender = "";
      }

      if (this.formData.title != this.other) {
        this.formData.title_other = "";
      }
    },
    validateAge() {
      if (
        this.formData.dob_day != null &&
        this.formData.dob_month != null &&
        this.formData.dob_year != null
      ) {
        let age = this.calculateAge(
          this.formData.dob_day,
          this.formData.dob_month,
          this.formData.dob_year
        );
        this.applicantAge = " You are " + age + " years old";
        const msg =
          "Invalid date (" +
          this.formData.dob_day +
          "-" +
          this.formData.dob_month +
          "-" +
          this.formData.dob_year +
          "). Kindly check and select a vaild date";
        if (this.monthsWith30days.includes(this.formData.dob_month)) {
          var vals = [31];
          if (vals.includes(parseInt(this.formData.dob_day))) {
            this.showPageRequestError(msg);
            this.applicantAge = "";
            this.formData.dob_day = null;
            this.formData.dob_month = null;
            this.formData.dob_year = null;
          }
        }

        if (
          this.leapYears.includes(this.formData.dob_year) &&
          this.formData.dob_month == "February"
        ) {
          var vals = [30, 31];
          if (vals.includes(parseInt(this.formData.dob_day))) {
            this.showPageRequestError(msg);
            this.applicantAge = "";
            this.formData.dob_day = null;
            this.formData.dob_month = null;
            this.formData.dob_year = null;
          }
        }
        if (
          !this.leapYears.includes(this.formData.dob_year) &&
          this.formData.dob_month == "February"
        ) {
          var vals = [29, 30, 31];
          if (vals.includes(parseInt(this.formData.dob_day))) {
            this.showPageRequestError(msg);
            this.applicantAge = "";
            this.formData.dob_day = null;
            this.formData.dob_month = null;
            this.formData.dob_year = null;
          }
        }
      }
    },
    calculateAge(day, month, year) {
      let strDate = day + " " + month + ", " + year;
      let convertedDate = new Date(strDate);

      var diff_ms = Date.now() - convertedDate.getTime();
      var age_dt = new Date(diff_ms);

      let calculatedAge = Math.abs(age_dt.getUTCFullYear() - 1970);

      return calculatedAge;
    },
    populatePermanentHomeAddress() {
      const msg = "Address of Applicant has not been completed";
      if (this.formData.same_address_as_ph_address == this.yes) {
        if (this.formData.address_line1 != null) {
          this.formData.ph_address_line1 = this.formData.address_line1;
          this.formData.ph_address_line2 = this.formData.address_line2;
          this.formData.ph_address_line3 = this.formData.address_line3;
          this.formData.ph_address_line4 = this.formData.address_line4;
        } else {
          this.showPageRequestError(msg);
          this.formData.same_address_as_ph_address = "";
        }
      } else {
        this.formData.ph_address_line1 = "";
        this.formData.ph_address_line2 = "";
        this.formData.ph_address_line3 = "";
        this.formData.ph_address_line4 = "";
      }
    },
    validateIsAthlete() {
      if (this.formData.is_athlete == this.no) {
        this.formData.last_competition = "";
        this.formData.sports_rep_institution = this.na;
      }
    },
    validateIsDisable() {
      if (this.formData.is_disable == this.no) {
        this.formData.disability = "";
        this.formData.disability_other = "";
        this.showOtherDisabilityField = false;
      }
    },
    validateSelectedDisability() {
      let selected_disabilities = this.$utils.toArray(this.formData.disability);
      let special_need = selected_disabilities.find((el) => el === this.other);
      if (special_need) {
        this.showOtherDisabilityField = true;
      } else {
        this.showOtherDisabilityField = false;
        this.formData.disability_other = "";
      }
      // else {
      //   let new_selection = selected_disabilities.filter((el) => el != this.other);
      //   this.formData.disability = new_selection;
      // }
    },
    validateIsEmployed() {
      if (this.formData.is_employed == this.no) {
        this.formData.employment_nature = "";
        this.formData.employment_address = "";
        this.formData.employment_nature_other = "";
      }
    },
    validateSpecifyOther(item, other) {
      if (item != this.other) {
        other = "";
      }
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
    async verifyNationalID() {
      if (this.formData.national_id_type && this.formData.national_id_number) {
        this.verifying = true;
        let payload = {
          id_type: this.formData.national_id_type,
          id_number: this.$utils.upper(this.formData.national_id_number),
        };
        let url = `applicants/verifynationalid`;
        ApiService.post(url, payload)
          .then((resp) => {
            this.verifying = false;
            let res = resp.data;
            let name = res?.name;
            if (name) {
              this.formData.is_national_id_verified =
                res?.is_national_id_verified;
              this.formData.id_card_verified_name = res?.name;
              this.formData.id_card_verified_dob = res?.dateOfBirth;

              var fullName = name.split(" ");

              this.formData.firstname = fullName[0];
              this.formData.lastname = fullName[fullName.length - 1];

              this.formData.national_id_verify_count += 1;

              this.flashMsg(
                this.$t("success"),
                `${name}`
              );
            }
          })
          .catch((err) => {
            this.verifying = false;
            this.showPageRequestError(err);
          });
      }
    },
    checkStudentNationality() {
      if (this.formData.is_foreign_student == this.yes) {
        this.formData.national_id_type = "Passport";
        this.formData.national_id_type_disbale = true;
      } else {
        this.formData.national_id_type = this.ghanacard;
      }
    }
  },
  watch: {},
  async mounted() {
    this.applicantTitles = this.$menus.title_list;
    this.load();
  },
  async created() { },
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}

.p-inputgroup-addon {
  min-width: auto !important;
  padding: 0;
}</style>